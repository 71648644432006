@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* typography */
.h1-regular {
  @apply font-work-sans font-normal text-7xl;
}
.h1-medium {
  @apply font-work-sans font-medium text-7xl;
}
.h1-semiBold {
  @apply font-work-sans font-semibold text-7xl;
}
.h1-bold {
  @apply font-work-sans font-bold text-7xl;
}

.h2-regular {
  @apply font-work-sans font-normal text-6xl;
}
.h2-medium {
  @apply font-work-sans font-medium text-6xl;
}
.h2-semiBold {
  @apply font-work-sans font-semibold text-6xl;
}
.h2-bold {
  @apply font-work-sans font-bold text-6xl;
}

.h3-regular {
  @apply font-work-sans font-normal text-5xl;
}
.h3-medium {
  @apply font-work-sans font-medium text-5xl;
}
.h3-semiBold {
  @apply font-work-sans font-semibold text-5xl;
}
.h3-bold {
  @apply font-work-sans font-bold text-5xl;
}

.h4-regular {
  @apply font-work-sans font-normal text-3xl xs:text-4xl;
}
.h4-medium {
  @apply font-work-sans font-medium text-3xl xs:text-4xl;
}
.h4-semiBold {
  @apply font-work-sans font-semibold text-3xl xs:text-4xl;
}
.h4-bold {
  @apply font-work-sans font-bold text-3xl xs:text-4xl;
}

.h5-regular {
  @apply font-work-sans font-normal text-2xl xs:text-3xl;
}
.h5-medium {
  @apply font-work-sans font-medium text-2xl xs:text-3xl;
}
.h5-semiBold {
  @apply font-work-sans font-semibold text-2xl xs:text-3xl;
}
.h5-bold {
  @apply font-work-sans font-bold text-2xl xs:text-3xl;
}

.h6-regular {
  @apply font-work-sans font-normal text-xl xs:text-2xl;
}
.h6-medium {
  @apply font-work-sans font-medium text-xl xs:text-2xl;
}
.h6-semiBold {
  @apply font-work-sans font-semibold text-xl xs:text-2xl;
}
.h6-bold {
  @apply font-work-sans font-bold text-xl xs:text-2xl;
}

.subheading-regular {
  @apply font-work-sans font-normal text-lg xs:text-xl;
}
.subheading-medium {
  @apply font-work-sans font-medium text-lg xs:text-xl;
}
.subheading-semiBold {
  @apply font-work-sans font-semibold text-lg xs:text-xl;
}
.subheading-bold {
  @apply font-work-sans font-bold text-lg xs:text-xl;
}

.p1-regular {
  @apply font-poppins font-normal text-sm xs:text-base;
}
.p1-medium {
  @apply font-poppins font-medium text-sm xs:text-base;
}
.p1-semiBold {
  @apply font-poppins font-semibold text-sm xs:text-base;
}
.p1-bold {
  @apply font-poppins font-bold text-sm xs:text-base;
}

.p2-regular {
  @apply font-poppins font-normal text-xs xs:text-sm;
}
.p2-medium {
  @apply font-poppins font-medium text-xs xs:text-sm;
}
.p2-semiBold {
  @apply font-poppins font-semibold text-xs xs:text-sm;
}
.p2-bold {
  @apply font-poppins font-bold text-xs xs:text-sm;
}

.p3-regular {
  @apply font-poppins font-normal text-xs;
}
.p3-medium {
  @apply font-poppins font-medium text-xs;
}
.p3-semiBold {
  @apply font-poppins font-semibold text-xs;
}
.p3-bold {
  @apply font-poppins font-bold text-xs;
}

.text-button-medium {
  @apply font-work-sans font-medium text-sm xs:text-base;
}
.text-button-small {
  @apply font-work-sans font-normal text-sm;
}
