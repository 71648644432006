@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 263 90 63 100%;
    --primary-foreground: 263 90 63 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 263 90 63 100%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 263 90 63 100%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 263 90 63 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 263 90 63 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 263 90 63 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 263 90 63 98%;

    --primary: 263 90 63 98%;
    --primary-foreground: 263 90 63 100%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 263 90 63 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 263 90 63 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 263 90 63 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }

  @import "../styles/typography.css";

  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }

  svg {
    pointer-events: none;
  }

  path {
    pointer-events: auto;
  }

  /* custom-scrollbar.css */

  /* For Chrome, Safari, and Opera */
  ::-webkit-scrollbar {
    @apply h-2 w-2;
  }

  ::-webkit-scrollbar-thumb {
    @apply bg-primaryGON-100 rounded hover:bg-primaryGON-200 focus:bg-primaryGON-300;
  }

  :root {
    scrollbar-color: rgb(231 217 252 / 1);
    scrollbar-width: 0.25rem;
  }

  .ui-laptop {
    @apply hidden lg:block;
  }
  .ui-tablet {
    @apply hidden lg:hidden xs:block;
  }

  .ui-tablet-mini {
    @apply hidden sm:hidden xs:block;
  }
  .ui-phone {
    @apply xs:hidden;
  }

  .ui-laptop-tablet {
    @apply hidden xs:block;
  }

  .ui-tablet-phone {
    @apply lg:hidden;
  }

  .py-gon {
    @apply py-3 xs:py-6 md:py-12;
  }

  .px-gon {
    @apply px-3 xs:px-6 md:px-12;
  }

  .pl-gon {
    @apply pl-3 xs:pl-6 md:pl-12;
  }
  .pr-gon {
    @apply pr-3 xs:pr-6 md:pr-12;
  }

  .container-mini {
    @apply px-3 xs:px-6 md:px-12;
  }

  .grid-product-list {
    @apply grid grid-cols-2 xs:grid-cols-3 md:grid-cols-4  gap-2 xs:gap-4;
  }

  .grid-product-list-4 {
    @apply grid grid-cols-4 xs:grid-cols-3 lg:grid-cols-4 gap-2 xs:gap-4;
  }

  .grid-product-list-search {
    @apply grid grid-cols-2 lg:grid-cols-3 gap-2 xs:gap-4;
  }

  .grid-footer-list {
    @apply grid grid-cols-2 xs:grid-cols-3 lg:grid-cols-4 gap-10;
  }

  @keyframes slideRightLoadAnimation {
    from {
      transform: translateX(-10px);
      /* opacity: 0; */
    }
    to {
      transform: translateX(100%);
      /* opacity: 1; */
    }
  }
  .load-animation {
    animation: slideRightLoadAnimation 10s infinite;
  }

  @keyframes opacityAnim {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .load-animation-opacity {
    animation: opacityAnim 20s;
  }

  @keyframes bounceLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(5px);
    }
  }
  .bounce-left {
    animation: bounceLeft 1s infinite;
  }

  @keyframes bounceRight {
    1000% {
      transform: translateX(0);
    }
    0% {
      transform: translateX(5px);
    }
  }
  .bounce-right {
    animation: bounceRight 1s infinite;
  }
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}